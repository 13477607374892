<template>
  <main class="main" role="main">
    <div class="page bg">
      <div class="inner">
        <div class="page_con" id="page_con">
          <div class="lump" style="border-bottom: unset;">
            <label class="input srh_type02">
              <input
                type="search"
                placeholder="제목, 내용 검색"
                v-model="text"
                @keyup.enter="fnSearchPage"
              />
              <button class="btn_search" @click="fnSearchPage">검색</button>
            </label>
          </div>
          <div class="wzp_list_wrap">
            <div class="list_top">
              <div class="left">
                <div class="total">
                  총 {{ $Util.formatNumber2(totalCnt, { unit: '건' }) }}
                </div>
              </div>
              <div class="right">
                <button
                  class="li_btn col"
                  :class="{ on: rowType === 1 }"
                  @click="fnToggleView(1)"
                ></button
                ><!--'on'-->
                <button
                  class="li_btn row"
                  :class="{ on: rowType === 2 }"
                  @click="fnToggleView(2)"
                ></button>
                <!--'on'-->
                <label class="select">
                  <select v-model="category">
                    <option :value="''">전체</option>
                    <option :value="categoryMap['1001'].text">{{
                      categoryMap['1001'].name
                    }}</option>
                    <option :value="categoryMap['1002'].text">{{
                      categoryMap['1002'].name
                    }}</option>
                    <option :value="categoryMap['1003'].text">{{
                      categoryMap['1003'].name
                    }}</option>
                    <option :value="categoryMap['1005'].text">{{
                      categoryMap['1005'].name
                    }}</option>
                  </select>
                </label>
              </div>
            </div>
            <div class="party_list_wrap bg" style="margin-top: 2rem;">
              <div class="row_type" style="padding-bottom: 2rem;">
                <swiper
                  :slides-per-view="1"
                  autoHeight
                  :autoplay="{ delay: 3000 }"
                  @slideChange="onSlideChange"
                >
                  <swiper-slide
                    tag="ul"
                    class="wzp_list_ul"
                    style="padding: 0; grid-gap: unset;"
                    v-for="(item, index) in bestCommentList"
                    :key="index"
                  >
                    <li
                      class="wzp_list_li click_bg_dt"
                      style="flex-direction: column"
                      @click="fnView(item)"
                    >
                      <div class="flex_area">
                        <div class="box txt">
                          <div class="party_ttl">
                            <div class="party_tag">
                              <div class="left">
                                <div
                                  class="pt_tag logo"
                                  style="--pt-txt-color: #fff;"
                                  :style="{
                                    '--pt-bg-color': $ConstCode.getCategoryByText(
                                      item.category
                                    ).color
                                  }"
                                >
                                  {{
                                    $ConstCode.getCategoryByText(item.category)
                                      .name
                                  }}
                                </div>
                              </div>
                            </div>
                            <span style="font-weight: 500;">{{
                              item.titleKr
                            }}</span>
                          </div>
                        </div>
                        <div class="box img">
                          <div class="thumb">
                            <div class="inner">
                              <img :src="item.thumbnail" :alt="item.titleKr" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="comment_list m_0">
                        <ul class="comment_list_ul p_0">
                          <li class="comment_list_li p_0">
                            <div class="top">
                              <div class="main_star_heart">
                                <div class="rank">
                                  <div
                                    class="stars"
                                    :class="getClassName(item.starRating)"
                                  >
                                    <!--'0.5점: n05', '1.0점: n10', ~ '5.0점: n50'-->
                                    <ul>
                                      <li></li>
                                      <li></li>
                                      <li></li>
                                      <li></li>
                                      <li></li>
                                    </ul>
                                  </div>
                                  <div class="rank_nb">
                                    <span>{{ item.starRating }}</span>
                                  </div>
                                </div>
                                <div class="heart_area">
                                  <div class="heart_nb">
                                    {{ item.recommendCount }}
                                  </div>
                                  <label class="checkbox">
                                    <input
                                      type="checkbox"
                                      :checked="item.recommendMine"
                                    />
                                    <span
                                      class="label"
                                      @click.prevent="
                                        fnUpdateCommentRecommend(item)
                                      "
                                    >
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <div class="comment_list_ttl">
                                <div class="left">
                                  <div class="name">{{ item.name }}</div>
                                  <div class="color" v-show="item.critic">
                                    Critic
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="btm ellipsis">
                              <span
                                ><!--붙여쓰기-->{{ item.comment
                                }}<!--붙여쓰기--></span
                              >
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </swiper-slide>
                  <div class="pager" v-if="!$Util.isEmpty(bestCommentList)">
                    <ul>
                      <li
                        :class="{ on: index === activeIndex }"
                        v-for="(item, index) in bestCommentList"
                        :key="index"
                      ></li>
                    </ul>
                  </div>
                </swiper>
              </div>
            </div>
            <div class="wzp_list" :class="{ row_type: rowType === 2 }">
              <!--'가로형: row_type'-->
              <wizzpedia-list :list="list" v-if="!$Util.isEmpty(list)" />
              <div class="empty_wrap" v-else>
                <div class="empty">
                  <div>위즈피디아 검색 결과가 없습니다.</div>
                </div>
              </div>
              <observe-visible
                :key="initCnt"
                :page="page"
                :limit="limit"
                :totalCnt="totalCnt"
                @fetch="fnListFunction"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'
import WizzpediaList from '@/components/views/community/wizzpedia/list/index.vue'

export default {
  name: 'community-wizzpedia-search-index',
  components: { WizzpediaList },
  setup() {
    const state = componentState()
    return { ...state }
  }
}
</script>

<style scoped></style>
