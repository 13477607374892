import { reactive, toRefs, getCurrentInstance, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { CommonFunction } from '@/common/CommonFunction'

const INIT_PAGE = 0
const INIT_LIMIT = 6
const INIT_TOTAL_CNT = 0

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const state = reactive({
    page: INIT_PAGE,
    limit: INIT_LIMIT,
    category: route.query.category || '',
    totalCnt: INIT_TOTAL_CNT,
    rowType: 1,
    text: route.query.search,
    search: route.query.search,
    params: computed({
      get: () => {
        return {
          page: state.page + 1,
          search: state.search,
          category: state.category
        }
      }
    }),
    list: [],
    initCnt: 0,
    categoryMap: proxy.$_.keyBy(proxy.$ConstCode.CATEGORY2_VALUE, 'id'),
    listScrollTop: 0,
    pointer: computed(() => (window.PointerEvent ? true : false)),
    activeTab: 2,
    activeIndex: 0,
    bestCommentList: [],
    touch: {}
  })
  const { switchTab, start, move, end, setTouchPos } = CommonFunction(state)
  const fnToggleView = (type = 1) => {
    state.rowType = type
  }
  const fnListFunction = async () => {
    const res = await proxy.$WizzpediaSvc.postWizzpediaSearchWizzpedia(
      state.params
    )
    if (res.resultCode === '0000') {
      state.page++
      state.totalCnt = res.count
      state.list = state.list.concat(
        res.list.map(item => {
          return {
            ...item
          }
        })
      )
    }
  }
  const fnReset = () => {
    state.page = INIT_PAGE
    state.limit = INIT_LIMIT
    state.totalCnt = INIT_TOTAL_CNT
    state.list = []
  }
  const fnSearchPage = async () => {
    await router.replace({
      path: '/community/wizzpedia/search',
      query: { search: state.text, category: state.category }
    })
  }

  const fnCount = async () => {
    const res = await proxy.$WizzpediaSvc.postWizzpediaMain()
    state.bestCommentList = res.bestCommentList
  }

  const getClassName = val => {
    const score = Number(val) * 10
    if (score < 1) {
      return ''
    }
    return `n${score}`
  }

  const onSlideChange = event => {
    state.activeIndex = event.activeIndex
  }

  const fnView = async item => {
    await router.push({
      name: 'community-wizzpedia-view-index',
      query: {
        id: item.id,
        category: item.category
      }
    })
  }

  /** watch **/
  watch(() => state.category, fnSearchPage)

  const init = async () => {
    await fnCount()
  }
  init()

  return {
    ...toRefs(state),
    fnToggleView,
    fnListFunction,
    fnReset,
    fnSearchPage,
    switchTab,
    start,
    move,
    end,
    setTouchPos,
    fnView,
    getClassName,
    onSlideChange
  }
}
